import Vue from 'vue'
window.Vue = Vue
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
import axios from 'axios'
import VueAnimate from 'vue-animate-scroll'
Vue.use(VueAnimate)

import { extend } from 'vee-validate'
import { required, email, regex } from 'vee-validate/dist/rules'
import AppFixedHeader from './components/AppFixedHeader'
import ReviewSlider from './components/ReviewSlider'
import WhySlider from './components/WhySlider'
import ImageSlider from './components/ImageSlider'
import FeatureSlider from './components/FeatureSlider'
import AppNavBar from './components/AppNavBar'
import ContactForm from './components/ContactForm'
import Accordion from './components/Accordion'
import Tabs from './components/Tabs'
import Tab from './components/Tab'
import TabSearch from './components/TabSearch'
import TabPrice from './components/TabPrice'
import AccordionPlans from './components/AccordionPlans'
import AccordionChurch from './components/AccordionChurch'
import AccordionReg from './components/AccordionReg'
import AccordionEvents from './components/AccordionEvents'
Vue.component('AppFixedHeader', AppFixedHeader)
Vue.component('AppNavBar', AppNavBar)
Vue.component('ReviewSlider', ReviewSlider)
Vue.component('WhySlider', WhySlider)
Vue.component('ImageSlider', ImageSlider)
Vue.component('FeatureSlider', FeatureSlider)
Vue.component('Accordion', Accordion)
Vue.component('Tabs', Tabs)
Vue.component('TabSearch', TabSearch)
Vue.component('Tab', Tab)
Vue.component('TabPrice', TabPrice)
Vue.component('AccordionPlans', AccordionPlans)
Vue.component('AccordionChurch', AccordionChurch)
Vue.component('AccordionReg', AccordionReg)
Vue.component('AccordionEvents', AccordionEvents)
Vue.component('ContactForm', ContactForm)


const files = require.context('./components/icons/', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))


Vue.prototype.$axios = axios
Vue.config.productionTip = false


extend('required', {
  ...required,
  message: 'The {_field_} is required'
})

extend('email', email)
extend('regex', regex)

extend('min', {
  validate (value, args) {
    return value.length >= args.length
  },
  params: ['length'],
  message: 'The {_field_} field must have at least {length} characters'
})

extend('max', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field may not be greater than {length} characters'
})

extend('length', {
  validate (value, args) {
    return value.length <= args.length
  },
  params: ['length'],
  message: 'The {_field_} field can have only {length} of checked items'
})
