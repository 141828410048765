<template>
  <div id="nav">
    <div class="max-w-screen-xl 2xl:max-w-screen-2xl mx-auto px-8 py-4 lg:py-8">
      <div class="md:w-full relative flex justify-between items-center lg:gap-x-12">
        <div class="-mb-12 relative lg:mb-0">
          <slot name="brand" />
        </div>
        <div class="slide-menu  hidden lg:flex items-center justify-end md:w-full text-white font-headline text-base">
          <button type="button" @click="isActive = !isActive;addo();addh();" class="text-center text-primary font-lato text-sm uppercase tracking-widest	" name="button">
            <svg class="h-8 w-8 mx-auto mb-2" fill="#BA9765" viewBox="0 0 27 28.5">
            <rect y="0" width="27" height="1.5"/>
            <rect y="12.6" width="27" height="1.5"/>
            <rect y="27" width="27" height="1.5"/>
            </svg>
            menu</button>
        </div>
        <div class="h-6 lg:hidden">
          <button type="button" @click="isMenu = !isMenu;addo();addh();" class="text-center text-primary font-lato text-sm uppercase tracking-widest	" name="button">
            <svg class="h-8 w-8 mx-auto mb-2" fill="#BA9765" viewBox="0 0 27 28.5">
            <rect y="0" width="27" height="1.5"/>
            <rect y="12.6" width="27" height="1.5"/>
            <rect y="27" width="27" height="1.5"/>
            </svg>
            menu</button>
        </div>
      </div>
    </div>
    <div :class="{ desktopmenu: isActive }"  class="absolute main-show w-screen hidden lg:block h-screen bg-black top-0 right-0 left-0 bottom-0 invisi">
      <div class="max-w-screen-xl 2xl:max-w-screen-2xl mx-auto px-8 py-4 lg:py-8">
        <div class="md:w-full relative flex justify-between items-center lg:gap-x-12">
          <div class="-mb-12 relative lg:mb-0">
            <slot name="brand" />
          </div>
          <div class="flex justify-center items-center gap-x-8 w-8/12">
            <a href="/funeral-notices" class="text-white font-lato border-primary border-2 text-sm py-2 px-8 block">Funeral Notices</a>
            <a href="/donate" class="text-white font-lato border-primary border-2 text-sm py-2 px-8 block">Make a Donation</a>
            <!-- <a href="#" class="text-white font-lato border-primary border-2 text-sm py-2 px-8 inline-flex items-center gap-x-2"><icon-camera></icon-camera>Funeral Live Stream</a> -->
          </div>
          <div class="slide-menu  hidden lg:flex items-center justify-end text-white font-headline text-base">
            <button type="button" @click="isActive = !isActive;removeo();removeh();" class="text-center text-primary font-lato text-sm uppercase tracking-widest	" name="button">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 mx-auto mb-2" fill="#BA9765" viewBox="0 0 28.1 28.1">
              <polygon class="st0" points="28.1,1.1 27,0 14,13 1.1,0 0,1.1 13,14 0,27 1.1,28.1 14,15.1 27,28.1 28.1,27 15.1,14 "/>
              </svg>
              CLOSE</button>
          </div>
        </div>
      </div>
      <div class="max-w-screen-xl 2xl:max-w-screen-2xl mx-auto px-8 py-4 lg:py-8">
        <div class="grid grid-cols-5 gap-x-8">
          <div class="">
            <p class="text-primary text-xl italic border-b pb-2" style="border-color: rgba(255,255,255,0.3);">When Someone Dies</p>
            <slot name="someonedies" />
          </div>
          <div class="">
            <p class="text-primary text-xl italic border-b pb-2" style="border-color: rgba(255,255,255,0.3);">The Service</p>
            <slot name="service" />
            <p class="text-primary text-xl italic mt-8 border-b pb-2" style="border-color: rgba(255,255,255,0.3);">After the Funeral</p>
            <slot name="after" />
          </div>
          <div class="">
            <p class="text-primary text-xl italic border-b pb-2" style="border-color: rgba(255,255,255,0.3);">Memorials</p>
            <slot name="memorials" />
          </div>
          <div class="">
            <p class="text-primary text-xl italic border-b pb-2" style="border-color: rgba(255,255,255,0.3);">Funeral Plans</p>
            <slot name="plans" />
            <p class="text-primary text-xl italic mt-8 border-b pb-2" style="border-color: rgba(255,255,255,0.3);">Helpful Contacts</p>
            <slot name="helpful" />
          </div>
          <div class="">
            <p class="text-primary text-xl italic border-b pb-2" style="border-color: rgba(255,255,255,0.3);">Our Company</p>
            <slot name="company" />
          </div>
        </div>
      </div>
    </div>
    <div :class="{ desktopmenu: isMenu }"  class="absolute w-screen block lg:hidden h-screen bg-black top-0 right-0 left-0 bottom-0 invisib">
      <div class="h-full overflow-x-hidden pb-12">
        <div class="mx-auto px-8 py-4 lg:py-8">
          <div class="md:w-full relative flex justify-between items-center lg:gap-x-12">
            <div class="relative lg:mb-0">
              <slot name="brand" />
            </div>
            <div class="text-white font-headline text-base">
              <button type="button" @click="isMenu = !isMenu;removeo();removeh();" class="text-center text-primary font-lato text-sm uppercase tracking-widest	" name="button">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 mx-auto mb-2" fill="#BA9765" viewBox="0 0 28.1 28.1">
                <polygon class="st0" points="28.1,1.1 27,0 14,13 1.1,0 0,1.1 13,14 0,27 1.1,28.1 14,15.1 27,28.1 28.1,27 15.1,14 "/>
                </svg>
                CLOSE</button>
            </div>
          </div>
        </div>
        <div class="mx-auto px-8 py-4 ">
          <accordion title="When Someone Dies">
            <slot name="someonedies" />
          </accordion>
          <accordion title="The Service">
            <slot name="service" />
          </accordion>
          <accordion title="After the Funeral">
            <slot name="after" />
          </accordion>
          <accordion title="Memorials">
            <slot name="memorials" />
          </accordion>
          <accordion title="Funeral Plans">
            <slot name="plans" />
          </accordion>
          <accordion title="Helpful Contacts">
            <slot name="helpful" />
          </accordion>
          <accordion title="Our Company">
            <slot name="company" />
          </accordion>
        </div>
        <div class="text-center space-y-4 text-center mt-8">
          <div>
            <a href="/funeral-notices" class="text-white font-lato w-8/12 border-primary border-2 mx-auto py-2 px-8 inline-block">Funeral Notices</a>
          </div>
          <div>
            <a href="/donate" class="text-white font-lato w-8/12 border-primary border-2 mx-auto py-2 px-8 inline-block">Make a Donation</a>
          </div>
          <div>
            <!-- <a href="#" class="text-white font-lato w-8/12 border-primary justify-center border-2 mx-auto py-2 px-8 inline-flex items-center gap-x-2"><icon-camera></icon-camera>Funeral Live Stream</a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isOpen: false,
      isOpenLeft: false,
      services:false,
      isActive: false,
      isMenu: false,
    }
  },
  methods: {
    addo () {
    document.querySelector('body').classList.toggle('overflow-hidden')
    },
    addh () {
      document.querySelector('body').classList.toggle('max-h-screen')
    },
    removeo () {
    document.querySelector('body').classList.toggle('overflow-hidden')
    },
    removeh () {
      document.querySelector('body').classList.toggle('max-h-screen')
    },
  }
}
</script>
<style lang="scss">
.invisi{
  margin-top: -105vh;
  transition: ease-in-out 0.5s all;
}
.invisib{
  margin-top: -200vh;
  transition: ease-in-out 0.5s all;
}
.desktopmenu{
  margin-top: 0;
}
</style>
