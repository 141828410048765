<template>
  <div>
    <div class="flex flex-wrap items-stretch mt-8 mb-8">
      <button id="registar" class="inline-block text-left w-1/3 lg:w-56 border-col pb-2 font-bold font-lato text-15 border-b hover:cursor-pointer" :class="{'border-added': selected === 2}" @click="selecttab(2)">Registrar Offices</button>
      <button id="support" class="inline-block text-left flex w-1/3 lg:w-56 border-col font-bold font-lato text-15 pb-2 border-b hover:cursor-pointer" :class="{'border-added': selected === 1}" @click="selecttab(1)">Support</button>
      <button id="event" class="inline-block text-left flex lg:mt-0 w-1/3 lg:w-56 border-col pb-2 font-bold font-lato text-15 border-b hover:cursor-pointer" :class="{'border-added': selected === 3}" @click="selecttab(3)">Events</button>
    </div>
    <div v-show="selected===1">
      <slot name="support" />
    </div>
    <div v-show="selected===2">
      <slot name="registrar" />
    </div>
    <div v-show="selected===3">
      <slot name="events" />
    </div>
  </div>
</template>
<script>
export default {
data () {
  return {
    selected: 1,
  }
},
methods: {
  selecttab(tab){
    this.selected = tab
  }
}
}
</script>
<style lang="scss">
  .border-added{
    border-bottom:2px solid #BA9765!important;
  }
  .border-col{
    border-color: rgba(255,255,255,0.3);
  }
</style>
